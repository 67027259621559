import {Route, Switch, useLocation, withRouter} from 'react-router-dom'
import {adminRoutes, dashboardRoutes, selfServiceRoutes} from './LazyRoutes'
import {formatQueryString, getQueryParams} from 'util/url'
import {useEffect, useRef} from 'react'

import EventRoutes from 'events/app/EventRoutes'
import FacebookLoginIframeScreen from './login/FacebookLoginIframeScreen'
import LoginScreen from './login/LoginScreen'
import {USER_ID_DIFFERS_PARAM} from './constants'
import {omit} from 'util/common'

// This is the main router for all applications.
// We currently lazy load both the Admin and Dashboard apps since they're prefixed.
// The Events app and its routes are always loaded.
function RoutesComponent({trackingParams, isCustomDomain, history, location}) {
  const routerLocation = useLocation()
  const prevLocationPathname = useRef()

  // Scroll to top on new page navigations (PUSH actions)
  useEffect(() => {
    if (history.action === 'PUSH') {
      window.scrollTo(0, 0)
    }
    // Rerun the effect when the pathname or query string changes
  }, [history.action, history.location.pathname, history.location.search])

  // Trim out unwanted query params (for now just `USER_ID_DIFFERS_PARAM`). Make sure to also add
  // to GLOBAL_QUERY_PARAM_BLACKLIST in withScreenData to avoid refetching when params are removed.
  useEffect(() => {
    const queryParams = getQueryParams(location.search)
    if (queryParams[USER_ID_DIFFERS_PARAM]) {
      history.replace({
        search: formatQueryString(omit(queryParams, USER_ID_DIFFERS_PARAM)),
      })
    }
    // This only needs to run on initial mount, since the server is the only place this param is
    // supplied, and we want to just immediately trim it out when the client boots.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Support jumping to anchor links from internal (react-router) links
  useEffect(() => {
    if (
      routerLocation.pathname !== prevLocationPathname &&
      routerLocation.hash
    ) {
      // Wait a tick until the page finishes rendering.
      setTimeout(() => {
        const el = document.getElementById(routerLocation.hash.slice(1))
        if (el) {
          el.scrollIntoView()
        }
      }, 0)
    }
    prevLocationPathname.current = routerLocation.pathname
  })

  // Be sure to keep the routes in sync with routes in urls.py and custom_domain_urls.py.
  // Also, if multiple tabs are to be displayed on the same screen,
  // be sure to pass a unique `key` to each route to force a refetch for each tab.
  return (
    <Switch>
      {/* Paths that don't use our common layout. */}
      <Route exact path="/login/">
        <LoginScreen />
      </Route>
      {!isCustomDomain && (
        <Route
          exact
          path="/embed/login/facebook/"
          component={FacebookLoginIframeScreen}
        />
      )}

      {/* Self-service organization creation */}
      {!isCustomDomain && (
        <Route path="/organization/" render={() => selfServiceRoutes({})} />
      )}

      {/* Admin app */}
      {!isCustomDomain && <Route path="/_/" render={() => adminRoutes({})} />}

      {/* Dashboard app */}
      <Route
        path="/dashboard/"
        render={() => dashboardRoutes({isCustomDomain, trackingParams})}
      />

      {/* Event app */}
      <EventRoutes
        isCustomDomain={isCustomDomain}
        trackingParams={trackingParams}
      />
    </Switch>
  )
}

const Routes = withRouter(RoutesComponent)
export default Routes
